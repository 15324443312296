import { computed, watch, type Ref } from 'vue'
import router from '@/router'

interface UseLobbyRouteOptions {
  isLobbyVisible: Ref<boolean>
  onRouteChange: (showLobby: boolean) => void
}

export const useLobbyRoute = ({ isLobbyVisible, onRouteChange }: UseLobbyRouteOptions) => {
  const lobbyId = computed(() => Number(router.currentRoute.value.params.id))

  watch(
    () => router.currentRoute.value,
    (route) => {
      const showLobby = route.query.lobby === 'true'
      onRouteChange(showLobby)
    }
  )

  watch(isLobbyVisible, (showLobby) => {
    if (router.currentRoute.value.matched.length) {
      router.push({
        query: {
          lobby: showLobby ? 'true' : undefined
        }
      })
    }
  })

  return {
    lobbyId
  }
}
