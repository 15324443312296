<script setup lang="ts">
import { useRouter } from 'vue-router'
import { SCButton, ButtonVariant } from '@nsftx/seven-components'
import STDeviceInfo from './STDeviceInfo.vue'
import STLobbyFooterItem from '../lobby/STLobbyFooterItem.vue'
import STClock from './STClock.vue'
import STIdleTimerBar from './STIdleTimerBar.vue'
import STHelpFooterItem from '../help/STHelpFooterItem.vue'
import STTicketCheckFooterItem from '../STTicketCheckFooterItem.vue'

const router = useRouter()

const navigateToPrint = () => {
  router.push({ name: 'printTest' })
}
</script>

<template>
  <div class="st-footer sc-bg-500" data-cy="footerFull">
    <div class="st-footer__left" data-cy="footerLeft">
      <div class="sc-mr-3" data-cy="iddleTimer">
        <STIdleTimerBar />
      </div>
      <STDeviceInfo />
      <STHelpFooterItem />
      <STLobbyFooterItem />
      <STTicketCheckFooterItem />
      <SCButton
        prepend-icon="printer"
        class="sc-mr-1 sc-my-1"
        height="60px"
        :variant="ButtonVariant.Secondary"
        @click="navigateToPrint"
      >
        <span class="sc-ml-3"> Print </span>
      </SCButton>
    </div>
    <div class="st-footer__right" data-cy="footerRight">
      <STClock />
    </div>
  </div>
</template>

<style lang="scss">
.st-footer {
  --st-footer-shadow-color: rgba(0, 0, 0, 0.12);

  position: relative;
  display: flex;
  box-shadow:
    0px 0px 16px var(--st-footer-shadow-color),
    0px -4px 16px var(--st-footer-shadow-color);

  &__left {
    display: flex;
    width: 100%;
  }
}
</style>
