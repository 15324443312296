<script setup lang="ts">
import { ScrollableContainer } from '@nsftx/seven-client-retail-vue-common'
import { SCIcon, SCNavigationList, SCNavigationListItem } from '@nsftx/seven-components'

const games = [
  {
    icon: 'n-i-keno',
    title: 'Keno',
    productId: 'keno'
  },
  {
    icon: 'n-i-virtual-drag-races',
    title: 'Virtual Drag Races',
    productId: 'virtual-drag-races'
  },
  {
    icon: 'n-i-live-betting',
    title: 'Live Betting',
    productId: 'live-betting'
  },
  {
    icon: 'n-i-greyhound-races',
    title: 'Greyhound Races',
    productId: 'greyhound-races'
  },

  {
    icon: 'n-i-lucky-six',
    title: 'Lucky Six',
    productId: 'lucky-six'
  },
  {
    icon: 'n-i-lottery',
    title: 'Lottery',
    productId: 'lottery'
  },
  {
    icon: 'n-i-lucky-x',
    title: 'Lucky X',
    productId: 'lucky-x'
  },
  {
    icon: 'n-i-slot-car-races',
    title: 'Slot Car Races',
    productId: 'slot-car-races'
  },

  {
    icon: 'n-i-roulette',
    title: 'Roulette',
    productId: 'roulette'
  },
  {
    icon: 'n-i-lottery',
    title: 'Numbers Betting',
    productId: 'numbers-betting'
  },
  {
    icon: 'n-i-virtual-horse-races',
    title: 'Virtual Horse Races',
    productId: 'virtual-horse-races'
  }
]

const emit = defineEmits<{
  (e: 'on:menuItemClick', productId: string): void
}>()

const onHelpSidebarMenuClick = (product: { productId: string }) => {
  emit('on:menuItemClick', product.productId)
}
</script>

<template>
  <ScrollableContainer container-classes="st-h-100" :to-top="false">
    <SCNavigationList large>
      <SCNavigationListItem
        v-for="(product, index) in games"
        :key="index"
        @click="onHelpSidebarMenuClick(product)"
      >
        <template #prepend-icon>
          <SCIcon :icon="product.icon" size="lg" />
        </template>
        <span>{{ product.title }}</span>
      </SCNavigationListItem>
    </SCNavigationList>
  </ScrollableContainer>
</template>
