<script setup lang="ts">
import { SCButton, ButtonVariant } from '@nsftx/seven-components'
import { computed } from 'vue'
import { storeToRefs } from 'pinia'
import { useTicketCheckStore } from '@/stores/ticket-check/ticketCheckStore'

const ticketCheckStore = useTicketCheckStore()
const { isTicketCheckFormOpened, isTicketCheckDetailsOpened } = storeToRefs(ticketCheckStore)
const { showTicketCheckModal } = ticketCheckStore

const buttonVariant = computed(() =>
  isTicketCheckFormOpened.value || isTicketCheckDetailsOpened.value
    ? ButtonVariant.Brand
    : ButtonVariant.Secondary
)
</script>

<template>
  <div class="sc-ml-1 sc-mr-1" data-cy="ticketCheckButton">
    <SCButton
      width="100%"
      prepend-icon="live-betting"
      class="sc-mr-1 sc-my-1"
      :variant="buttonVariant"
      height="60px"
      @click="showTicketCheckModal()"
    >
      <span class="sc-ml-3" data-cy="ticketCheckTitle">
        {{ $t('ticket_check') }}
      </span>
    </SCButton>
  </div>
</template>
