import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useSpinnerStore = defineStore('spinnerStore', () => {
  const isSpinnerOpened = ref(false)

  const showSpinner = () => {
    isSpinnerOpened.value = true
  }

  const closeSpinner = () => {
    isSpinnerOpened.value = false
  }

  return {
    isSpinnerOpened,
    showSpinner,
    closeSpinner
  }
})
