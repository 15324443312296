<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { SCModalNext, SCButton, ButtonVariant } from '@nsftx/seven-components'
import { useTicketCheckStore } from '@/stores/ticket-check/ticketCheckStore'

const { isTicketCheckDetailsOpened, ticketId } = storeToRefs(useTicketCheckStore())
</script>

<template>
  <SCModalNext v-model="isTicketCheckDetailsOpened" with-close-icon class="st-ticket-check-details">
    <template #title>System | Open</template>

    <template #content> Ticket ID: {{ ticketId }}</template>

    <template #actions>
      <SCButton :variant="ButtonVariant.Primary" data-cy="payoutButton">
        {{ $t('common_payout') }}
      </SCButton>
    </template>
  </SCModalNext>
</template>

<style lang="scss">
.st-ticket-check-details {
  --st-modal-content-width: calc(100% - 64px);

  .sc-modal-next__panel {
    width: var(--st-modal-content-width) !important;
    max-width: var(--st-modal-content-width) !important;
  }

  @media (min-width: 1600px) and (max-width: 1919px) {
    --st-modal-content-width: calc(100% - 200px);
  }

  @media (min-width: 1920px) {
    --st-modal-content-width: calc(100% - 520px);
  }
}
</style>
