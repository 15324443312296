<script setup lang="ts">
import { useClock } from '@nsftx/seven-client-retail-vue-common'

const { date, time } = useClock({ locale: 'en-GB', interval: 5000 })
</script>

<template>
  <div class="st-clock sc-px-6" data-cy="timeAndDate">
    <div class="sc-text-primary-2 sc-text-caption-2" data-cy="date">
      {{ date }}
    </div>

    <div class="sc-text-primary-1 sc-text-h6" data-cy="time">
      {{ time }}
    </div>
  </div>
</template>

<style lang="scss">
.st-clock {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: end;
  height: 100%;
  white-space: nowrap;
}
</style>
