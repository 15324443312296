import { ref, computed } from 'vue'
import { defineStore } from 'pinia'
import { useLobbyRoute } from '@/composables/useLobbyRoute/useLobbyRoute'
import type { LobbyItem } from '@/modules/lobby/interfaces/LobbyItem'

export const useLobbyStore = defineStore('lobby', () => {
  const lobbyItems = ref<LobbyItem[]>([
    {
      type: 'page',
      thumbnail: {
        image:
          'https://svncms-cdn.s3.eu-central-1.amazonaws.com/assets/cms/production/demo/test/NSoft.webp',
        backgroundColor: '#76BAFF',
        overlayBackgroundImage: 'linear-gradient(180deg, rgba(36, 38, 40, 0) 0%, #0080FF 100%)'
      },
      translationKey: 'lobby.homepage',
      route: {
        name: 'home',
        query: {},
        params: {}
      },
      title: 'Home'
    },
    {
      type: 'page',
      thumbnail: {
        image:
          'https://svncms-cdn.s3.eu-central-1.amazonaws.com/assets/cms/staging/nsoft/lobby/f5ba16b2d79d6957a6f700da32170d72.webp',
        backgroundColor: 'rgba(101, 6, 174, 0.3)',
        overlayBackgroundImage:
          'linear-gradient(180deg, rgba(101, 6, 174, 0.0001) 36.57%, #6506AE 87.38%)'
      },
      widget: {
        source: {
          type: 'IFRAME',
          url: 'https://www.winner.ro/winner-fun'
        }
      },
      title: 'Winner Ro'
    },
    {
      type: 'page',
      thumbnail: {
        image:
          'https://svncms-cdn.s3.eu-central-1.amazonaws.com/assets/cms/production/demo/test/NSoft.webp',
        backgroundColor: '#76BAFF',
        overlayBackgroundImage: 'linear-gradient(180deg, rgba(36, 38, 40, 0) 0%, #0080FF 100%)'
      },
      translationKey: 'lobby.about',
      route: {
        name: 'about',
        query: {},
        params: {}
      },
      title: 'About'
    },
    {
      type: 'page',
      thumbnail: {
        image:
          'https://svncms-cdn.s3.eu-central-1.amazonaws.com/assets/cms/staging/nsoft/lobby/f5ba16b2d79d6957a6f700da32170d72.webp',
        backgroundColor: 'rgba(101, 6, 174, 0.3)',
        overlayBackgroundImage:
          'linear-gradient(180deg, rgba(101, 6, 174, 0.0001) 36.57%, #6506AE 87.38%)'
      },
      widget: {
        source: {
          type: 'IFRAME',
          url: 'https://www.nsoft.com/?v=1726230556082&integrationType=gravityGateway&application=terminal&platform=seven'
        }
      },
      title: 'NSoft'
    },
    {
      type: 'page',
      thumbnail: {
        image:
          'https://svncms-cdn.s3.eu-central-1.amazonaws.com/assets/cms/production/demo/test/NSoft.webp',
        backgroundColor: '#76BAFF',
        overlayBackgroundImage: 'linear-gradient(180deg, rgba(36, 38, 40, 0) 0%, #0080FF 100%)'
      },
      translationKey: 'lobby.homepage',
      route: {
        name: 'home',
        query: {},
        params: {}
      },
      title: 'Home'
    }
  ])
  const isLobbyVisible = ref(false)
  const lobbyItemsContainer = ref<HTMLElement | null>(null)

  const { lobbyId } = useLobbyRoute({
    isLobbyVisible,
    onRouteChange: (showLobby) => toggleLobby(showLobby)
  })
  const lobbyItemContent = computed(() => lobbyItems.value[lobbyId.value])

  const toggleLobby = (showLobby: boolean) => {
    isLobbyVisible.value = showLobby
  }

  const setLobbyItemsContainer = (el: HTMLElement) => {
    lobbyItemsContainer.value = el
  }

  return {
    lobbyItems,
    lobbyItemContent,
    lobbyItemsContainer,
    isLobbyVisible,
    toggleLobby,
    setLobbyItemsContainer
  }
})
