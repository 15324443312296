import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useTicketCheckStore = defineStore('ticketCheckStore', () => {
  const ticketId = ref('')
  const isTicketCheckFormOpened = ref(false)
  const isTicketCheckDetailsOpened = ref(false)

  const showTicketCheckModal = () => {
    ticketId.value = '' // every time we open ticket check modal, reset ticket id
    isTicketCheckFormOpened.value = true
  }

  const closeTicketCheckModal = () => {
    isTicketCheckFormOpened.value = false
  }

  const showTicketCheckDetailsModal = () => {
    isTicketCheckDetailsOpened.value = true
  }

  const closeTicketCheckDetailsModal = () => {
    isTicketCheckDetailsOpened.value = false
  }

  return {
    ticketId,
    isTicketCheckFormOpened,
    isTicketCheckDetailsOpened,
    showTicketCheckModal,
    closeTicketCheckModal,
    showTicketCheckDetailsModal,
    closeTicketCheckDetailsModal
  }
})
