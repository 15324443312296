<script setup lang="ts">
import { computed } from 'vue'
import { storeToRefs } from 'pinia'
import { SCToastNotificationGroup } from '@nsftx/seven-components'
import { useNotificationsStore } from '@/stores/notifications/notificationsStore'

const notificationsStore = useNotificationsStore()
const { notifications } = storeToRefs(notificationsStore)

const notificationsPosition = 'bottom'

const notificationsClasses = computed(() => ({
  'st-notifications--with-overlay': notifications.value.some(
    (notification) => notification.uiBlocking
  )
}))
</script>

<template>
  <Teleport to="body">
    <div class="st-notifications" :class="notificationsClasses">
      <SCToastNotificationGroup v-model="notifications" :position="notificationsPosition" />
    </div>
  </Teleport>
</template>

<style lang="scss">
.st-notifications {
  position: relative;

  &--with-overlay::after {
    content: '';
    position: fixed;
    inset: 0;
    z-index: var(--sc-zindex-modal);
    background-color: rgb(0, 0, 0);
    opacity: 60%;
  }
}
</style>
