<script setup lang="ts">
import { onMounted, useTemplateRef } from 'vue'
import {
  TimerBar,
  TimerOrientation,
  TimerProgression,
  useIdleTimer
} from '@nsftx/seven-client-retail-vue-common'
import { useIdleWarningModalStore } from '@/stores/idleWarningModal/idleWarningModalStore'
import { useOnIdleTimerEnd } from '@/composables/useOnIdleTimerEnd/useOnIdleTimerEnd'

const timerRef = useTemplateRef('timerRef')
const idleWarningModalStore = useIdleWarningModalStore()
const { onIdleTimerEnd } = useOnIdleTimerEnd()

const idleTime = 120_000
const transitionDelay = 5_000
const idleWarningModalDelay = 5_000
const timerPosition = {
  left: 0,
  bottom: 0
}

const onTimerReset = () => {
  timerRef.value?.resetTimerAnimation()
  idleWarningModalStore.setShowWarningModal(false)
}

const onTimerTick = (tick: number) => {
  if (tick === idleWarningModalDelay) idleWarningModalStore.setShowWarningModal(true)
}

const idleTimer = useIdleTimer({
  duration: idleTime,
  transitionDelay,
  onEnd: onIdleTimerEnd,
  onReset: onTimerReset,
  onTick: onTimerTick
})

onMounted(() => {
  idleTimer.startIdleTimer()
})
</script>

<template>
  <TimerBar
    ref="timerRef"
    :duration="idleTime"
    :transition-delay="transitionDelay"
    bg-color="var(--sc-main-primary-faded)"
    loading-bar-color="var(--sc-brand)"
    :orientation="TimerOrientation.Vertical"
    :progression-style="TimerProgression.DESC"
    :position="timerPosition"
  />
</template>
