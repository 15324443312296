import { AxiosError, isAxiosError } from 'axios'

const parseMessage = (error: unknown) =>
  isAxiosError(error) ? parseAxiosErrorMessage(error) : parseJsErrorMessage(error)

const parseJsErrorMessage = (error: unknown) => {
  if (error instanceof Error) {
    const message = error.message || ''
    const name = error.name || 'Error'
    const stack = error.stack ? `\nStack: ${error.stack}` : ''

    return message || name || stack
  }

  return String(error)
}

const parseAxiosErrorMessage = (error: AxiosError): string =>
  error.message || error.request?.statusText || ''

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const parseJsErrorCode = (error: any) => error.code || error.name || ''

const parseAxiosErrorCode = (error: AxiosError) => error.response?.status || error.code || ''

const parseCode = (error: unknown): string =>
  isAxiosError(error) ? parseAxiosErrorCode(error) : parseJsErrorCode(error)

const parse = (error: unknown) => ({
  message: parseMessage(error),
  code: parseCode(error)
})

const parseUpstream = (error: unknown) => ({
  upstream_message: parseMessage(error),
  upstream_code: parseCode(error)
})

const errorParser = {
  parse,
  parseUpstream,
  parseMessage,
  parseCode
}

export default errorParser
