import { storeToRefs } from 'pinia'
import { watch } from 'vue'
import { NotificationVariant } from '@nsftx/seven-components'
import { useDeviceManagementPrinterStatusStore } from '@nsftx/seven-client-retail-vue-dm'
import i18n from '@/plugins/translationsPlugin'
import { useNotificationsStore } from '@/stores/notifications/notificationsStore'

const PRINTER_STATUS_ERROR_NOTIFICATION_ID = 'printer_status_error'
const PRINTER_STATUS_WARNING_NOTIFICATION_ID = 'printer_status_warning'

const usePrinterStatus = () => {
  const { printerStatus } = storeToRefs(useDeviceManagementPrinterStatusStore())

  if (!printerStatus.value.valid) {
    handlePrinterStatusError()
  }

  watch(printerStatus, onPrinterStatusChange)
}

const onPrinterStatusChange = () => {
  const { hasPrinterStatusError } = useDeviceManagementPrinterStatusStore()

  if (hasPrinterStatusError()) {
    handlePrinterStatusError()
    return
  }

  handlePrinterStatusNotification()
}

const handlePrinterStatusNotification = () => {
  const { printerStatus, hasPrinterStatusWarning } = useDeviceManagementPrinterStatusStore()
  const { message } = printerStatus
  const { t } = i18n.global
  const notificationsStore = useNotificationsStore()
  const showWarningsConfig = true
  const warningNotification = {
    id: PRINTER_STATUS_WARNING_NOTIFICATION_ID,
    variant: hasPrinterStatusWarning()
      ? NotificationVariant.Warning
      : NotificationVariant.Informational,
    messages: message ? [t(message)] : undefined,
    timeout: 3000
  }

  notificationsStore.closeNotificationById(PRINTER_STATUS_ERROR_NOTIFICATION_ID)
  notificationsStore.closeNotificationById(PRINTER_STATUS_WARNING_NOTIFICATION_ID)

  if (showWarningsConfig && message) {
    notificationsStore.showNotification(warningNotification)
  }
}

const handlePrinterStatusError = () => {
  const { printerStatus, isStatusPaperOut } = useDeviceManagementPrinterStatusStore()
  const notificationsStore = useNotificationsStore()
  const { t } = i18n.global

  const printerUiBlockingConfig = true
  const printerStatusMessage = printerStatus.message ? `${t(printerStatus.message)}.` : ''
  const errorMessage = `${printerStatusMessage} ${t('printer_money_actions_blocked')}`
  const isPaperOutUiBlockingError = printerUiBlockingConfig && isStatusPaperOut()
  const errorNotification = {
    id: PRINTER_STATUS_ERROR_NOTIFICATION_ID,
    variant: NotificationVariant.Error,
    uiBlocking: isPaperOutUiBlockingError,
    closeDisabled: isPaperOutUiBlockingError,
    messages: [isPaperOutUiBlockingError ? t('printer_paper_error_notification') : errorMessage]
  }

  notificationsStore.showNotification(errorNotification)
}

export {
  usePrinterStatus,
  PRINTER_STATUS_ERROR_NOTIFICATION_ID,
  PRINTER_STATUS_WARNING_NOTIFICATION_ID
}
