import { DmPrintError } from '@nsftx/seven-client-retail-vue-dm'
import errorParser from './errorParser'

const parseMessage = (error: unknown) =>
  error instanceof DmPrintError
    ? error.options?.context?.printerStatus?.message || ''
    : errorParser.parseMessage(error)

const parseUpstream = (error: unknown) => ({
  upstream_message: parseMessage(error),
  upstream_code: errorParser.parseCode(error)
})

export default {
  parseUpstream
}
