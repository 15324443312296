<script setup lang="ts">
import { computed, ref } from 'vue'
import { SCThumbnail, ThumbnailSizes } from '@nsftx/seven-components'
import type { GamesListItem } from '@/modules/games/interfaces/GamesListItem'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const LandscapeLayouts = {
  Fit: 'fit',
  Scroll: 'scroll'
} as const

export type LandscapeLayout = (typeof LandscapeLayouts)[keyof typeof LandscapeLayouts]

// const
const gamesList: GamesListItem[] = [
  {
    name: 'Keno',
    type: 'Sports',
    backgroundImage: 'assets/sb/keno_background_image.png',
    largeThumbnail: 'assets/sb/keno_product_image_large.png',
    backgroundColor: 'rgba(0,0,0,0)',
    gradient: 'linear-gradient(180deg, rgba(73, 153, 84, 0) 36.57%, rgba(0,0,0,0) 87.38%)',
    new: true,
    disabled: '',
    label: 'New'
  },
  {
    name: 'Virtual Drag Races',
    type: 'Sports',
    backgroundImage: 'assets/sb/virtual_drag_races_background_image.png',
    largeThumbnail: 'assets/sb/virtual_drag_races_product_image_large.png',
    backgroundColor: 'rgba(0,0,0,0)',
    gradient: 'linear-gradient(180deg, rgba(73, 153, 84, 0) 36.57%, rgba(0,0,0,0) 87.38%)',
    new: false,
    disabled: '',
    label: ''
  },
  {
    name: 'Greyhound Races',
    type: 'Sports',
    backgroundImage: 'assets/sb/greyhound_races_background_image.png',
    largeThumbnail: 'assets/sb/greyhound_races_product_image_large.png',
    backgroundColor: 'rgba(0,0,0,0)',
    gradient: 'linear-gradient(180deg, rgba(73, 153, 84, 0) 36.57%, rgba(0,0,0,0) 87.38%)',
    new: false,
    disabled: '',
    label: ''
  },
  {
    name: 'Live Betting',
    type: 'Sports',
    backgroundImage: 'assets/sb/live_betting_background_image.png',
    largeThumbnail: 'assets/sb/live_betting_product_image_large.png',
    backgroundColor: 'rgba(0,0,0,0)',
    gradient: 'linear-gradient(180deg, rgba(73, 153, 84, 0) 36.57%, rgba(0,0,0,0) 87.38%)',
    new: false,
    disabled: '',
    label: ''
  },
  {
    name: 'Lucky Six',
    type: 'Sports',
    backgroundImage: 'assets/sb/lucky_six_background_image.png',
    largeThumbnail: 'assets/sb/lucky_six_product_image_large.png',
    backgroundColor: 'rgba(0,0,0,0)',
    gradient: 'linear-gradient(180deg, rgba(73, 153, 84, 0) 36.57%, rgba(0,0,0,0) 87.38%)',
    new: false,
    disabled: 'Disabled',
    label: ''
  },
  {
    name: 'Lottery',
    type: 'Sports',
    backgroundImage: 'assets/sb/lottery_background_image.png',
    largeThumbnail: 'assets/sb/lottery_product_image_large.png',
    backgroundColor: 'rgba(0,0,0,0)',
    gradient: 'linear-gradient(180deg, rgba(73, 153, 84, 0) 36.57%, rgba(0,0,0,0) 87.38%)',
    new: false,
    disabled: '',
    label: ''
  },
  {
    name: 'Lucky X',
    type: 'Sports',
    backgroundImage: 'assets/sb/lucky_x_background_image.png',
    largeThumbnail: 'assets/sb/lucky_x_product_image_large.png',
    backgroundColor: 'rgba(0,0,0,0)',
    gradient: 'linear-gradient(180deg, rgba(73, 153, 84, 0) 36.57%, rgba(0,0,0,0) 87.38%)',
    new: false,
    disabled: '',
    label: ''
  },
  {
    name: 'Slot Car Races',
    type: 'Sports',
    backgroundImage: 'assets/sb/slot_car_races_background_image.png',
    largeThumbnail: 'assets/sb/slot_car_races_product_image_large.png',
    backgroundColor: 'rgba(0,0,0,0)',
    gradient: 'linear-gradient(180deg, rgba(73, 153, 84, 0) 36.57%, rgba(0,0,0,0) 87.38%)',
    new: false,
    disabled: '',
    label: ''
  },
  {
    name: 'Roulette',
    type: 'Sports',
    backgroundImage: 'assets/sb/roulette_background_image.png',
    largeThumbnail: 'assets/sb/roulette_product_image_large.png',
    backgroundColor: 'rgba(0,0,0,0)',
    gradient: 'linear-gradient(180deg, rgba(73, 153, 84, 0) 36.57%, rgba(0,0,0,0) 87.38%)',
    new: false,
    disabled: '',
    label: ''
  },
  {
    name: 'Numbers Betting',
    type: 'Sports',
    backgroundImage: 'assets/sb/numbers_betting_background_image.png',
    largeThumbnail: 'assets/sb/numbers_betting_product_image_large.png',
    backgroundColor: 'rgba(0,0,0,0)',
    gradient: 'linear-gradient(180deg, rgba(73, 153, 84, 0) 36.57%, rgba(0,0,0,0) 87.38%)',
    new: false,
    disabled: '',
    label: ''
  },
  {
    name: 'Virtual Horse Races',
    type: 'Sports',
    backgroundImage: 'assets/sb/virtual_horse_races_background_image.png',
    largeThumbnail: 'assets/sb/virtual_horse_races_product_image_large.png',
    backgroundColor: 'rgba(0,0,0,0)',
    gradient: 'linear-gradient(180deg, rgba(73, 153, 84, 0) 36.57%, rgba(0,0,0,0) 87.38%)',
    new: false,
    disabled: '',
    label: ''
  }
]
const gridColumnsHeightPatternInPortraitMode = new Map<number, number>([
  [1, 100],
  [2, 50],
  [3, 33.33],
  [4, 25],
  [5, 33.33],
  [6, 33.33]
])
const gridColumnsCountPatternInLanscapeMode = new Map<number, number>([
  [1, 1],
  [2, 2],
  [3, 3],
  [4, 2],
  [5, 3],
  [6, 3],
  [7, 4],
  [8, 4],
  [9, 3]
])
const columnsCountInLandscapeMode: number =
  gridColumnsCountPatternInLanscapeMode.get(gamesList?.length) || 3
const rowsCountInLandscapeMode: number = Math.ceil(gamesList?.length / columnsCountInLandscapeMode)
const isLandscapeOrientation = window.screen.orientation?.type === 'landscape-primary'
const isPortraitOrientation = window.screen.orientation?.type === 'portrait-primary'

// state
const selectedLandscapeLayout = ref<LandscapeLayout>('fit')

// computed
const gamesListClasses = computed(() => ({
  'st-home-games-grid-display--with-divider':
    gamesList.length > 8 && (selectedLandscapeLayout.value !== 'fit' || isPortraitOrientation),
  'st-home-games-grid-display--fit-layout':
    selectedLandscapeLayout.value === 'fit' && isLandscapeOrientation
}))

const gameFlexBasis = computed(
  () =>
    isLandscapeOrientation &&
    `calc((100% / ${columnsCountInLandscapeMode}) - 8px + (8px / ${columnsCountInLandscapeMode}))`
)

const gameMaxHeight = computed(
  () =>
    selectedLandscapeLayout.value === 'fit' &&
    `calc((100% - (8px * ${rowsCountInLandscapeMode})) / ${rowsCountInLandscapeMode})`
)

const gameHeight = computed(
  () =>
    (isLandscapeOrientation && gamesList?.length <= 3 && '100%') ||
    (isPortraitOrientation && `${gridColumnsHeightPatternInPortraitMode.get(gamesList?.length)}%`)
)

const gameMaxWidth = computed(
  () =>
    !isPortraitOrientation &&
    !gridColumnsHeightPatternInPortraitMode.get(gamesList?.length) &&
    gamesList?.length > 8 &&
    '50%'
)

const gameStyle = computed(() => ({
  flexBasis: gameFlexBasis.value || '',
  maxHeight: gameMaxHeight.value || '',
  height: gameHeight.value || '',
  maxWidth: gameMaxWidth.value || ''
}))
</script>

<template>
  <div class="st-home-games-grid-display" :class="gamesListClasses">
    <div
      v-for="game in gamesList"
      :key="game.name"
      :style="gameStyle"
      class="st-home-games-grid-display__game"
    >
      <SCThumbnail
        :size="ThumbnailSizes.xxl"
        :image="game.largeThumbnail"
        :background-image="game.backgroundImage"
        :background-color="game.backgroundColor"
        :alt="game.name"
        :blended="false"
        :label="game.label"
        :disabled="game.disabled"
        tabindex="0"
      >
        <template #title>
          {{ game.name }}
        </template>
      </SCThumbnail>
    </div>
  </div>
</template>

<style scoped lang="scss">
.st-home-games-grid-display {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  grid-column: 1 / -1;
  grid-row: 2 / -1;

  &--fit-layout {
    row-gap: 0px;
  }

  &--with-divider::after {
    content: '';
    width: 100%;
    max-height: 2.75rem;
    min-height: 2.37rem;
  }

  &__game {
    flex-grow: 1;
    flex-shrink: 1;
    font-size: 2.5rem;
    line-height: 2.5rem;
    height: 50%;
  }
}

@media (orientation: portrait) {
  .st-home-games-grid-display__game {
    flex: 1 1 49%;
    max-width: 100%;
    height: 25%;
  }
}
</style>
