<script setup lang="ts">
import STHelpSidebar from '../components/help/STHelpSidebar.vue'
import { useRouter } from 'vue-router'

const router = useRouter()

const onMenuItemClick = (productId: string) => {
  const { path } = router.currentRoute.value
  router.replace({ path, query: { gameId: productId } })
}
</script>

<template>
  <div class="help-view sc-p-3">
    <STHelpSidebar @on:menu-item-click="onMenuItemClick" />
    <div class="st-help-content"></div>
  </div>
</template>

<style scoped lang="scss">
.help-view {
  background-color: #1a1c1d;
  display: grid;
  grid-template-columns: 1fr 5fr;
}

@media (max-width: 1024px) {
  .help-view {
    grid-template-columns: 1fr 3fr;
  }
}
</style>
