<script setup lang="ts">
import { computed, inject, ref } from 'vue'
import STHomeGamesGridDisplay from '@/ui/components/STHomeGamesGridDisplay.vue'
import STMarketingBanner from '@/ui/components/STMarketingBanner.vue'
import { HOME_MAIN } from '@/utils/injections/injectionKeys'

const isMarketingBannerEnabled = ref<boolean>(true)

const homeMain = inject(HOME_MAIN)

const homePageClasses = computed(() => ({
  'st-home-page--no-banner': !isMarketingBannerEnabled.value
}))
</script>

<template>
  <main ref="homeMain" class="st-home-page" :class="homePageClasses">
    <div v-if="isMarketingBannerEnabled" class="st-home-page__marketing-banner">
      <STMarketingBanner>
        <img class="st-home-page__marketing-banner__image" src="/assets/sb/banner.png" />
      </STMarketingBanner>
    </div>
    <STHomeGamesGridDisplay />
  </main>
</template>

<style scoped lang="scss">
.st-home-page {
  position: relative;
  background-color: #1a1c1d;
  padding: 48px calc(100% * (2 / 24));
  overflow-x: hidden;
  overflow-y: scroll;
  display: grid;
  grid-template-rows: 144px repeat(2, 1fr);
  height: 100%;

  &::-webkit-scrollbar {
    display: none;
  }

  &__marketing-banner {
    width: 100%;
    height: 7.5rem;
    margin-bottom: 1.5rem;
    border-radius: 1rem;
    overflow: hidden;

    &__image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &--no-banner {
    .home-page__products-list {
      grid-row: 1 / -1;
    }
  }
}
</style>
