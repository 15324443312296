import { useTicketCheckStore } from '@/stores/ticket-check/ticketCheckStore'
import { useSpinnerStore } from '@/stores/spinner/spinnerStore'
import { logService } from '@nsftx/seven-client-retail-utils'

const LOG_PREFIX = '[ticketCheckService]'

const handleScannedBarcode = (barcode: string) => {
  const ticketCheckStore = useTicketCheckStore()

  logService.info(`${LOG_PREFIX} Ticket code scanned`, {
    ticket_code: barcode,
    code: 'ST_TICKET_CODE_SCAN'
  })

  ticketCheckStore.ticketId = barcode
  // close previously opened modals when new ticket scanned
  ticketCheckStore.closeTicketCheckModal()
  ticketCheckStore.closeTicketCheckDetailsModal()
  checkTicket()
}

const checkTicket = () => {
  const ticketCheckStore = useTicketCheckStore()
  const spinnerStore = useSpinnerStore()

  spinnerStore.showSpinner()

  setTimeout(() => {
    spinnerStore.closeSpinner()
    ticketCheckStore.showTicketCheckDetailsModal()
  }, 2000)
}

export default { handleScannedBarcode, checkTicket }
