import { ref } from 'vue'
import { defineStore } from 'pinia'
import { v4 as uuidv4 } from 'uuid'
import type STNotification from '@/modules/notifications/interfaces/STNotification'

export const useNotificationsStore = defineStore('notifications', () => {
  const notifications = ref<Array<STNotification>>([])

  const removeNotificationByUuid = (uuid: string): void => {
    notifications.value = notifications.value.filter((notification) => notification.uuid !== uuid)
  }

  const closeNotificationById = (id: string): void => {
    const notification = notifications.value.find((notification) => notification.id === id)

    if (notification?.uuid) {
      removeNotificationByUuid(notification.uuid)
    }
  }

  const showNotification = (notification: Omit<STNotification, 'uuid'>): void => {
    if (notification.id) closeNotificationById(notification.id)

    const newNotification: STNotification = { ...notification, uuid: uuidv4() }
    notifications.value.push(newNotification)
  }

  const clearNotifications = (): void => {
    notifications.value = []
  }

  return {
    removeNotificationByUuid,
    closeNotificationById,
    showNotification,
    clearNotifications,
    notifications
  }
})
