import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useIdleWarningModalStore = defineStore('idleWarningModal', () => {
  const showIdleWarningModal = ref<boolean>(false)

  const setShowWarningModal = (value: boolean) => {
    showIdleWarningModal.value = value
  }

  return {
    showIdleWarningModal,
    setShowWarningModal
  }
})
