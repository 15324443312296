<script setup lang="ts">
import { computed } from 'vue'
import { SCButton, ButtonVariant } from '@nsftx/seven-components'
import { useLobbyStore } from '@/stores/lobby/lobbyStore'
import { storeToRefs } from 'pinia'

const lobbyStore = useLobbyStore()
const { toggleLobby } = useLobbyStore()
const { isLobbyVisible } = storeToRefs(lobbyStore)

const buttonVariant = computed(() =>
  isLobbyVisible.value ? ButtonVariant.Brand : ButtonVariant.Secondary
)

const openLobby = () => {
  toggleLobby(true)
}
</script>

<template>
  <div class="sc-ml-1 sc-mr-1" data-cy="openLobbyButton">
    <SCButton
      width="100%"
      prepend-icon="globe-a2"
      class="sc-mr-1 sc-my-1"
      :variant="buttonVariant"
      height="60px"
      @click="openLobby"
    >
      <span class="sc-ml-3">
        {{ $t('lobby_title') }}
      </span>
    </SCButton>
  </div>
</template>
