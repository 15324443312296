<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { SCSpinner } from '@nsftx/seven-components'
import { useSpinnerStore } from '@/stores/spinner/spinnerStore'

const { isSpinnerOpened } = storeToRefs(useSpinnerStore())
</script>

<template>
  <div v-if="isSpinnerOpened" class="st-spinner" data-cy="spinner">
    <SCSpinner />
  </div>
</template>

<style lang="scss">
.st-spinner {
  background: black;
  opacity: 80%;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: var(--sc-zindex-above);
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
